.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--secondary-background-color);
  width: 100%;
}

.pagefooter{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--secondary-background-color);
}

.footerUpper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 18.8px;
}

.pageFotterUpper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 18.8px;
  background-color: var(--main-background-color2);
  justify-content: flex-end;
}
.footerLink{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  color: #ffffff;
  font-size: 1rem;
}

.footerUpperRight, .footerUpperLeft, .footerUpperCenter{
  display: flex;
  text-align: right;
  flex-direction: column;
  width: 100%;
  color: #000000;
}

.footerBottom {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.footerTop{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}

.leftFooterTop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5px;
  background-color: var(--secondary-background-color);
  flex: 1;
}

.centerFooterTop{
  display: flex;
  justify-content: center;
  flex: 5;
}


.footerTop > .rightTop{
  display: flex;
  flex: 5;
}


.warningDiv{
  width: 100%;
  color: var(--footer-font-color);
}

.hebWarning{
  direction: rtl;
}

.footertop> .leftTop{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5px;
  flex: 2;
}

.footerCopyrightText {
  font-size: 0.3rem;
  font-family: "Signika Negative", "Rubik";
  color: var(--footer-font-color);
  margin: 0;
  padding: 0 0 0 0;
  text-align: center;
}

.MuiSvgIcon-root.copyRightIcon {
  font-size: 0.4rem;
  color: var(--footer-font-color);

}

.MuiSvgIcon-root.footerIcon {
  font-size: 1.5625rem;
  color: #000000;
}

.MuiSvgIcon-root.footerIcon:hover {
  color: #0505ee;
}

.footerWhastAppIcon{
    height: 20px;
}

.findUs{
    font-size: 1rem;
    color: var(--footer-font-color);
    margin: 0;
    padding: 0 0 0 0;
    padding-right: 5px;
    text-align: center;
}
