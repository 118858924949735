.dishCardDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid black;
    border-radius: 10px;
        /* min-height: 11.25rem; */
    margin-block: 16px;
    padding: 10px;
    background-color: var(--main-background-color);
    border: var(--main-no-border);
    color: var(--main-font-color);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
}

.dishCardTitle {
    font-size: 1.2rem;
    display: flex;
    justify-content: flex-end;
    text-transform: lowercase;
}

.dishTitlesDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.hebSubtitle{
    direction: rtl;
}

.dishCardSubtitle {
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Urbanist','Rubik';
    display: flex;
    justify-content: center;
    text-transform: lowercase;
    width: 100%;
    text-align: center;
}

.dishCardType{
    font-size: 1rem;
    font-weight: 400;
    font-family: 'anisette-std','Rubik';
    display: flex;
    justify-content: flex-end;
}

.attributesDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.attributesDiv> div{
    text-transform: lowercase;
}

.kosherDiv {
    display: flex;
    flex:2;
    justify-content: center
}

.dishpriceDiv{
    display: flex;
    flex:2;
    justify-content: flex-start;
}

.typeDiv{
    flex:2;
    display: flex;
    justify-content: flex-end;
}