.home {
    flex-direction: column;
    transition: opacity 2s ease-in-out;
    opacity: 0;
    width: 100%;
    font-family: 'Urbanist';
    padding-bottom: '10px';
    font-family: var(--main-font-family);
  }
  
  .home.visible {
    opacity: 1;
  }
  
  .home.notVisable {
    opacity: 0;
  }

.homeGridItem.MuiGrid-root.MuiGrid-item{
    font-size: 1.2rem;
    overflow: hidden;
    background-color: var(--main-background-color2);
    border-radius: 16px;
    margin: 0 auto; 
border: var(--main-card-border);    /* Adjust the border radius as needed */
}

.MuiGrid-root.MuiGrid-container{
    margin: 0 auto;
    padding: 0 0 0 0;
    font-size: 1.2rem;
    color: #000000;
    width: 100%;
  }

.MuiGrid-root.MuiGrid-container > .MuiGrid-root.MuiGrid-item {
  padding:5px 0px;
  margin-block: 3px;
  direction: rtl;
}

.homeGridItem.MuiGrid-root.MuiGrid-item >a {
  color:var(--secondary-font-color);
}


.engButton {
  font-weight: 300;
  text-transform: lowercase;
}

.homeTitle{
  display: flex;
  font-size: 1.35rem;
  font-weight: 600;
  text-align: center;
  color: var(--main-font-color);
  justify-content: center;
  align-items: center;
  text-transform: lowercase;  
  text-decoration: underline;
  margin-block-end: 0px;
}