/*card2- wine card*/
.cardTwo {
  display: flex;
  flex:1;
  border-radius: 15px;
  flex-direction: row !important;
  justify-content: space-between;
  min-height: 12.5rem;
  position: relative;
  width: 100%;
  color: var(--main-font-color);
  font-family: var(--main-font-family);
  text-transform: var(--main-text-transform);
  border: var(--main-card-border);
  margin-block: 1rem;
  background-color: var(--main-background-color);
}

.pandaIcon{
  position: absolute;
  top:10px;
  right: 10px;
  font-size: 1.5rem;
  color: #000;
  max-height: 40px;
}

.cigarCard{
  display: flex;
  flex:1;
  border-radius: 15px;
  flex-direction: row !important;
  justify-content: space-between;
  min-height: 12.5rem;
  position: relative;
  width: 100%;
  color: var(--main-font-color);
  font-family: var(--main-font-family);
  text-transform: var(--main-text-transform);

}
.cigarCardDiv{
  border: var(--main-card-border);
  border-radius: 15px;
  background-color: var(--main-background-color);
  margin-block: 1rem;

}

.cigarInfoBtn{
  color: #000;
  border-radius: 15px;
  background-color: #967E68 ;
  border: var(--main-card-border);
  box-shadow: 0 0 5px 2px #000;
  font-family: var(--main-font-family);
  justify-content: center;
  font-size: 1rem;
}

.outOfStock {
  border: var(--main-card-border);
  display: flex;
  flex:1;
  border-radius: 15px;
  flex-direction: row !important;
  justify-content: space-between;
  margin-top: 1rem;
  height: 12.5rem;
  position: relative;
  width: 100%;
  color: #ffffff;
  background-color: #000000;
  font-family: var(--main-font-family);
  text-transform: var(--main-text-transform);
  opacity: 0.5;
}

.out-of-stock-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-7deg);
  color: rgb(255, 255, 255);
  background-color: #080909;
  padding: 5px;
  z-index: 3;
  pointer-events: none;
  font-size: 1.875rem;
  width: 95%;
}

.AlchebText{
  direction: rtl;
}

@media screen and (max-width: 395px) {
  .out-of-stock-text {
    font-size: 1.25rem;
  }
}

.card2-imageDiv {
  display: flex;
  flex: 4;
  justify-content: center;
  align-items: center;
  position: absolute;
  border: 1px solid #020202;
  border-radius: 15px;
  transition: transform 0.5s ease-in-out;
  padding: 1rem 1rem;
  background-color: #ffffff;
  height: 100%;
  width: 50%;
  right: 0;
}

.card2-imageDiv.slide {
  transform: translateX(-100%);
}

.card2image {
  height: 11.875rem;
  width: 100%;
  border: #020202;
  border: transparent;
}

.card2-text {
  flex: 1;
  padding: 1rem;
}

.card2Content {
  display: flex;
  flex: 4;
  max-width: 50%;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 1rem;
  text-overflow: ellipsis;
}

.card2-right {
  display: flex;
  flex: 4;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
}

.MuiButtonBase-root.linkWithoutSpace {
  color: var(--main-font-color);
  text-decoration: var(--main-link-decoration);
  text-transform: none;
    border-radius: 16px!important;
    border: var(--main-no-border);
    background-color: var(--secondary-background-color);  
}

.MuiButtonBase-root.MuiButton-root.linkWithoutSpace:hover{
  background-color: var(--secondary-background-color);  
  color: #ffffff!important;

}


.card2-titleDiv{
  display: flex;
  flex-direction: column;
  align-items: center;

}

.card2-title {  
  font-size: 1.4rem;
  margin-bottom: 0;
  font-family: var(--main-font-family);
  text-transform: var(--main-text-transform);
  font-weight: 300;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}



.hebTitle{
  direction: rtl;
  font-weight: 400;
}

.card2-button {
  display: flex;
  justify-content: center;
  align-items: center;
}


.priceDiv {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  padding-top: 0.125rem;
}

.priceDiv > div {
  margin-inline: 5px;
}

.priceDiv>span {
  display: flex;
}

.BottlePrice {
  align-items: center;
  display: flex;
}

.bottlePriceText{
  font-size: 1.2rem;
  margin: 0;
  align-items: center;
 }

.MuiSvgIcon-root.cardLogos {
  font-size: 1.7rem
}

.card2-Subtitle {
  font-size: 1rem;
  font-weight: 400;
  padding: 1px;
  
}

.card2-subTitleDiv{
  display: flex;
  flex-direction: column;
}

.line{
  display: flex;
  justify-content: center;
  width: 100%;
  height: 0.09rem;
  background-color: var(--main-seperator-color);
  margin-block: 0.4rem;
}

.card2-Subtitle.hebSubTitle{
  direction: rtl;
  font-weight: 400;
}

.card2-details {
  font-size: 1.4rem;
  width: 100%;

  /*delete this later for alternate view with flexes*/
  display: flex;
  flex-direction: row;
  align-items: center;

}

.card2-span {
  flex: 4;
  padding-inline: 5px;
  width: 40%;
  display: flex;
}

.card2-span.card2-leftText {
  justify-content: end;
  font-weight: 500;
  text-align: center;
  width: 100%;
}

.beerCard-span.beerCard-leftText {
  justify-content: center;
  font-weight: 500;
  text-align: center;
  width: 100%;
}

.card2-span.card2-rightText {
  text-align: left;
  font-weight: 400;
}

.card2-span.card2-grape{
  text-align: center;
  justify-content: center;
  font-weight: 500;
}

.card2-blentText{
  text-align: center;
  display: flex;
  justify-content: center;
  font-weight: 500;
}

.infoIcon {
 z-index: 1;
 position: absolute;
 top: 5px;
  right: 5px;
  color: #000
}

.favoriteIcon {
  z-index: 1;
  position: absolute;
  top: 5px;
  left: 5px;
  color: #444343
 }

 .favoriteIcon.isFavorite {
  color: #ff0000;
 }

 .oneRowWinery{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-inline: 5px;
 }

 .oneRowWinery > span {

  margin-inline: 2px;
 }

 .bottlePriceDiv{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
 }

 .cupPriceDiv{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
 }



@media screen and (max-width: 1200px) {
  .card2-text {
    padding: 0;
  }


}

@media screen and (max-width: 775px) {
  .cardTwo {
    min-height: 11.25rem;
  }

  .card2image {
    height: 10.625rem;
    border-radius: 15px;
  }

  .card2-text {
    padding: 0;
  }

  .card2-title {
    font-size: 1.2rem;
  }

  .card2-Subtitle {
    font-size: 0.75rem;
  }

  .card2-details {
    font-size: 1.1rem;
    line-height: 1.2;
    margin-block: 0.2rem;
  }
}

@media screen and (max-width: 395px) {
  .cardTwo {
    min-height: 11.25rem;
  }

  .card2-text {
    padding: 0;
  }

  .card2-title {
    font-size: 1.1rem;

  }

  .card2-Subtitle {
    font-size: 0.7rem;
  }

  .card2image {
    width: 100%;
    height: 10.625rem;
    border-radius: 15px;
  }

  .card2-details {
    font-size: 0.8rem;
    line-height: 1.2;
    margin-block: 0.2rem;
  }
}

@media screen and (max-width: 335px) {
  .cardTwo {
    min-height: 11.25rem;
  }

  .card2Content,
  .card2-right {
    width: 50%;
  }

  .MuiSvgIcon-root.cardLogos {
    font-size: 1.5rem;
  }

  .card2-text {
    padding: 0;
  }

  .card2-title {
    font-size: 1rem;

  }

  .card2-Subtitle {
    font-size: 0.8rem;
  }

  .card2image {
    width: 100%;
    border-radius: 15px;
    padding: 2px;
  }
}


/*for spritz Card*/
.spritzCardImage {
  height: 11.875rem;
  width: 100%;
  border: #020202;
  border-radius: 16px;
}

.spritzCard-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  max-width: 50%;
  flex: 4;

}

.spritzCard-Left {
  display: flex;
  flex: 4;
  max-width: 80%;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 1rem;
  text-overflow: ellipsis;
}