.winePage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 100%; */
    padding: 0 0 0 0;
    padding-bottom: 20px;
    font-size: 1.2rem;
    color: #000000;
    text-align: center;
    overflow: hidden;
}

.winePageInfo {
    display: flex;
    flex-direction: column;
    
    margin: 0 auto;
    width: 100%;
    height: 100%;
    padding: 0 0 0 0;
    padding-bottom:10px;
    font-size: 1.2rem;
    color: #000000;
    text-align: center;
    overflow: hidden;
}

.winePageTitleDiv {
    display: flex;
    flex-direction: column;
    width: 95%;
    padding: 0 0 0 0;
    margin-bottom: 20px;
    font-size: 1.2rem;
    color: #000000;
    text-align: center;
    overflow: hidden;
    line-height: 1;
    background-color: #967E68;
}

.winePageTitleText {
    font-family: var(--main-font-family);
    text-transform: lowercase;
    color: #ffffff;
}

.wineInfoTitle {
    font-family: var(--main-font-family);
    text-transform: lowercase;
    color: #ffffff;
    font-size: 1.9rem;
    margin: 0;
}

.hebPageTitle{
    direction: rtl;
    font-weight: 300;
    color: #000000;
    font-size: 1.8rem;
}

.engPageTitle{
    direction: ltr;
    font-weight: 200;
    font-size:2.1rem;
}


.DishPageImageDiv{
   justify-content: center;
   position: relative;
   background-color: #ffffff;
}

.DishPageImage {
    height: 300px;
    width: 300px;
    border-radius: 15px;
}

.langIcon{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 1px solid #000000;

}

.languageIconDiv {
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
    position: absolute;
}

.languageIconDiv >* {
    margin: 0 0.5rem 0 0.5rem;
}

.languageIconWinePageDiv {
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
    margin-inline: 0.5rem;
}

.languageIconWinePageDiv >* {
    margin: 0 0.5rem 0 0.5rem;
}

.descText{
    font-family: 'Signika Negative', 'Rubik';
    font-size: 1.1em;
    color: #ffffff;
}

.descText >*{
    margin: 0;
}

.descDiv{
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 95%;
    /* background-color: #1E2423; */
    border-radius: 15px;
    /* box-shadow: 0 0 3px #967E68; */
    margin: auto;
    justify-content: center;
    align-items: center;
}

.hebDesc{
    direction: rtl;
    font-weight: 300;
}

.engDesc{
    direction: ltr;
    font-weight: 200;
}
/* 
@media (max-width: 420px) {

    .DishPageImage {
        height: 300px;
        width: 300px;
    }
    
} */

.wineInfo{
    display: flex;
    align-items: center;
    justify-content: center;
}

.wineInfoText{
    flex: 6;
    font-size: 1.25rem;
    justify-content: center;

    color: #ffffff;
    font-family: var(--main-font-family);
    margin-top: auto;
    margin-bottom: auto;
    text-transform: lowercase;
}

.wineGrapes{
    font-size: 1.25rem;
    justify-content: center;

    color: #ffffff;
    font-family: var(--main-font-family);
    margin-top: auto;
    margin-bottom: auto;
    text-transform: lowercase;
}

.hebWineInfo{
    direction: rtl;
    display: flex;
    font-weight: 400;
}

.wineInfoPrice{
    display: flex;
    flex-direction: row;
    color: #000000;
    font-size: 1.1rem;
    position: absolute;
    width: 70px;
}

.bottlePrice{
    bottom: 270px;
}

.cupPrice{
    bottom: 240px;
}

.wineInfoSeperator{
    flex: 1;
    color: #ffffff;
    font-size: 18px;
    margin-top: auto;
    margin-bottom: auto;
}



.wineInfo.pricetext{
    align-items: center;
    text-align: center;
font-size: 1.1rem;
font-family: 'Signika Negative', 'Rubik';
color: #ffffff;
}


