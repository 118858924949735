.App {
  text-align: center;
  font-family: var(--secondary-font-family);
  overflow:hidden;
}

/*scrollbar styling*/

::-webkit-scrollbar {
  width: 12px;
  /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #121A1C;
  /* Background of the scrollbar track */
  position: relative;

}

::-webkit-scrollbar-thumb {
  background: #967E68;
  /* Background of the scrollbar thumb */
  border-radius: 16px;
  /* Roundness of the scrollbar */
}

::-webkit-scrollbar-thumb:hover {
  background: #967E68;
  /* Background of the scrollbar thumb when hovered */
}

a{
  text-decoration: var(--main-link-decoration);
}

h2{
  margin-bottom: 0px;
}

p{
  margin-bottom: 0;
}






/*card styling*/

.css-1knaqv7-MuiButtonBase-root-MuiButton-root.goToButton {
  margin: auto;
  position: relative;
  top: 25px
}


/* navbar styling */
.navbar {
  top: 0;
  width: 100%;
  z-index: 2;
  border-bottom: 1px solid #e6e6e6;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  padding: 0px 15px;
}

.css-1hvnmzf-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #F1ECE6;
}

@media (max-width: 320px) {
  .navbar {
    width: 100% !important;
  }
}

.navbar-brand {
  margin: 0;
}

.navbarToggle {
  border-width: 0;
  padding-right: 0;
  margin-right: 0;
  width: 100px;
  display: flex;
  justify-content: flex-end;
}

.toggleIcon {
  color: #080808;
}

.MuiSvgIcon-root.toggleIcon {
  font-size: 3rem;
}

@media screen and (max-width: 768px) {
  .MuiSvgIcon-root.toggleIcon {
    font-size: 2rem;
  }

}

.navbarToggle.collapsed {
  border-width: 0;
  padding-right: 0;
  margin-right: 0;

  .toggleIcon {
    color: #888;

  }

}

.bg-light {
  background-color: #F1ECE6 !important;
}

.navbar-nav .nav-link {
  transition: opacity 2 ease-in-out;
}

.navbar-light .navbar-nav .nav-link {
  color: #0d0d0d;
  font-size: 1.2rem;
  font-family: var(--secondary-font-family);
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #967E68;
  /* Change to your desired link color */
}

.navbar-light .navbar-nav .nav-link.active-link {
  color: #050505;
  font-weight: 700;
  /* Change to your desired link color */
}

.nav-link {
  font-family: 'Signika Negative', 'Rubik';
  font-size: 1.2em;
  color: #61dafb;
  transition: opacity 2 ease-in-out;
  text-decoration: var(--main-link-decoration);
}

.App-logo {
  /*app logo should be center screen*/
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 85%;
  pointer-events: none;
}

/* .App-footer{
  background-color: #F1ECE6;
  width: 100%;
  justify-content: space-between;
  z-index: 1;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  padding: 0px 15px;
  position: relative;
  bottom: 0;
} */

/* app logo should be center screen 
.App-logo {
  animation: App-logo-spin infinite 20s linear;
} */



/*--------------------- Fade in for pages when routing--------------------- */
.fade-in-element {
  opacity: 0;
  transition: opacity 3s ease-in-out;
  /**/
}

.fade-in-element.visible {
  opacity: 1;
}

.fade-in-element.notVisable {
  opacity: 0;
}

.home {
  align-items: center;
  justify-content: center;
  align-content: center;
  align-content: center;
  transition: opacity 3s ease-in-out;

  .navbar-brand {
    margin: 0;
  }

  .bg-light {
    background-color: #F1ECE6 !important;
  }

  .navbar-nav .nav-link {
    transition: opacity 2 ease-in-out;
  }

  .navbar-light .navbar-nav .nav-link {
    color: #0d0d0d;
  }



  .nav-link {
    font-family: 'Urbanist', sans-serif;
    font-size: 1.5em;
    color: #61dafb;
    transition: opacity 2 ease-in-out;
  }

  .searchBar {
    margin-bottom: 50px;
    width: 100%;
  }

  #search-bar :placeholder-shown {
    text-align: center;
    color: var(--main-font-color);    
  }


  /* <---------- for the x button in searchbar ----------> */
  .clearButton, .searchButton {
    color: var(--main-font-color);
    background-color: transparent;
    position: absolute;
    bottom: 20%;
    left: 5%;
    /* background-color: #1E2423; */
    border-radius: 16px;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }




  .App-logo {
    /*app logo should be center screen*/
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 20%;
    pointer-events: none;
  }

  .fade-in-element {
    opacity: 0;
    transition: opacity 3s ease-in-out;
  }

  .fade-in-element.visible {
    opacity: 1;
  }

  .fade-in-element.notVisable {
    opacity: 0;
  }

  .home {
    align-items: center;
    justify-content: center;
    align-content: center;
    align-content: center;
    transition: opacity 3s ease-in-out;
    flex-direction: unset;
    /* Turn off flex direction */
  }

  .flex-grow {
    flex-grow: 1;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .home.visible {
    opacity: 1;
  }

  .home.notVisable {
    opacity: 0;
  }

  img {
    border-radius: 16;
  }

  @media (prefers-reduced-motion: no-preference) {
    /* Media query styles */
  }

  flex-grow: 1;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  ;
}

.home.visible {
  opacity: 1;
}

.home.notVisable {
  opacity: 0;
}

img {
  border-radius: 16;
}


@media (prefers-reduced-motion: no-preference) {}

.App-Body {
  background-image: var(--main-background-image);
  background-color: var(--main-background-color);
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: var(--main-font-color);
  flex: 1;
}

/* 
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
} */

/* <-------------------  CSS for the SearchBar -------------------> */
.searchBar {
  margin-bottom: 50px;
  width: 100%;
}

.textInput {
  background-color: var(--secondary-background-color) !important;
  border: var(--main-no-border) !important;
  width: 100%;
  border-radius: 16px;
  text-decoration: none;
  text-transform: none;
  text-align: center;
  min-height: 2.5rem;
  color: #ffffff;
  font-size: 1.2rem;
}

.textInput::placeholder{
  color: var(--main-font-color);
  opacity: 1;
}

.searchInput {
  margin-block: 10px;
  position: relative;
  border-radius: 16px !important;
}

.inputProps {
  color: var(--main-font-color);
  border-radius: 16px;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  border-radius: 16px;
}

/*Label for dropdown styling*/

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: var(--main-label-color);
}

.MuiFormLabel-root.MuiInputLabel-root.hebInputLabel {
  direction: rtl;
}

/* .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #F1ECE6;
  font-family: 'Urbanist';
} */


.center-placeholder input {
  text-align: center;
  color: var(--main-font-color);
  opacity: 1;
}


/*label style when there is text and not focused*/
/* .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root{
  color: #F1ECE6;
  font-family: 'Urbanist';
} */

/*label style when there is text and focused*/
/* .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused.inputLabel {
  color: #F1ECE6;
  font-family: 'Urbanist';
} */

.css-1d3z3hw-MuiOutlinedInput-notchedOutline.inputStyle {
  border-width: 0
}

.MuiGrid-root.dishgridView {
  margin: auto;
  justify-content: space-between;
}

.muicard-root {
  display: flex;
  flex-direction: column;
}

/* <-------------------  CSS for the Scroll To Top -------------------> */
.scrollToTop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 100px;
  width: 100px;
  background-color: #967E68;
  z-index: 1000;
  border-radius: 50px;
  box-shadow: 0 0 15px #d6bc9e;
}

.MuiSvgIcon-root.scrollToTop {
  font-size: 3rem;
  /*add border shadow*/
  display: flex;
}

@media screen and (max-width: 650px) {
  .MuiSvgIcon-root.scrollToTop {
    font-size: 2rem;
  }

}


/* <-------------------  CSS for the ageDialog -------------------> */

.dialogContainerHeb {
  direction: rtl;
}

.dialog>* {
  font-family: var(--main-font-family);
}

.MuiDialogTitle-root.dialogTitle {
  font-family: var(--main-font-family);
  text-transform: lowercase;
}

.MuiDialogTitle-root.dialogTitle.hebTitle {
  direction: rtl;
  text-align: right;
}

.MuiButtonBase-root.MuiButton-root.dialogButton {
  font-family: var(--main-font-family);
  font-size: 1em;
  text-transform: lowercase;
  text-decoration: underline;
  color: #050505;
}

.dialogText.MuiDialogContentText-root {
  font-family: var(--main-font-family);
  text-transform: lowercase;
  font-size: 1.1rem;
}

.dialogText.MuiDialogContentText-root.hebText {
  direction: rtl;
  text-align: right;
}

.MuiPaper-root.MuiDialog-paper {
  background-color: #6a410e;
}




/* <-------------------  CSS for the Font Size Switch -------------------> */

:root {
  --main-font-size: 16px;
  --main-background-color: #121A1C;
  --main-background-color2: #1E2423;
  --secondary-background-color: #967E68;

  --main-background-image: url('./Images/BackgroungImage_Final.png');

  --main-seperator-color: #967E68;

  --main--shadow-color: #967E68;
  --secondary-shadow-color: #000;

  --main-no-border: 0px;
  --main-card-border: 2px solid #967E68;
  --main-contact-button-border: 1px solid #ffffff;

  --main-link-decoration: none;

  --main-font-color:#fff;
  --secondary-font-color: #967E68;
  --footer-font-color: #000;

  --main-font-family: 'anisette-std', 'Rubik';
  --secondary-font-family: 'Urbanist', 'Rubik';
  --main-text-transform: lowercase;

  --main-label-color: #F1ECE6;

  --main-selected-color: #9b8f81;
}

body {
  font-size: var(--main-font-size);
}

html {
  font-size: var(--main-font-size);
}


/* <-------------------  CSS for the GoBackButton -------------------> */

.goBackButton{
  position: absolute;
  top: 100px;
  left: 15px;
  background-color: #121A1C;
  border: 1px solid #967E68;
  color: #967E68;
  padding: 4px;
  border-radius: 16px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}


/* <-------------------  CSS for the Accesability Tab -------------------> */

.accesablilityTab{
  background-color: #121A1C;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 1.2rem;

}

.acessablityXbutton{
  position:absolute;
  right: 0;
  top: 0;
  height: 1.8rem;
  width: 1.7rem;
}

.MuiDialogActions-root.specialActions{
  display: flex;
  justify-content: center;
  margin-bottom: 1.2rem;
  position:absolute;
  bottom: 25px;
  right: auto;
  width: 100%;
}

.MuiButtonBase-root.MuiButton-root.specialdialogButton {
  font-family: var(--main-font-family);
  font-size: 1em;
  text-transform: lowercase;
  text-decoration: underline;
  color: #050505;
  border: 1px solid #967E68;
  border-radius: 16px;
  background-color: #967E68;
  padding: 4px;
  margin: 0 0.5rem;
}

