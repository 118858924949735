.soonToBeDiv{
    margin-top: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translate(0,50%) rotate(-45deg);
    margin: 0 auto;
    width: 100%;
    height: 100%;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.soonToBeDiv.hebrew{
    transform: translate(0,80%) rotate(45deg);
}

.soonToBeText{
    font-size: 3.5rem;
    font-weight: 500;
    color: var(--main-font-color);
    margin-bottom: 20px;
}

.eventsTitle {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0.5rem 0;
    color: var(--main-font-color);
    font-family: 'anisette-std','Rubik';
    text-transform: lowercase;
  }