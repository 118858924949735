.wineCategory{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    width: 100%;
    height: 100%;
    background-color: var(--main-background-color2);
    padding: 10px;
    font-size: 1rem;
    font-family: var(--main-font-family);
    color: var(--secondary-font-color);
    border-radius: 15px;
    box-shadow: 0 0 5px var(--main--shadow-color);
    margin-Top: 15px;
    justify-content: center;  
    border-color: transparent;
}

.SkipLinks{
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    width: 100%;
    height: 100%;
    background-color: var(--main-background-color2);
    padding: 10px;
    font-size: 1rem;
    font-family: var(--main-font-family);
    color: var(--secondary-font-color);
    border-radius: 15px;
    box-shadow: 0 0 5px var(--main--shadow-color);
    margin-Top: 15px;
    justify-content: center;  
    
}

.using-keyboard .SkipLinks{
    display: flex;
}

.wineSubCategory{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    width: 100%;
    height: 100%;
    background-color:  var(--main-background-color2);
    padding: 10px;
    font-size: 1rem;
    font-family: var(--main-font-family);
    color: var(--secondary-font-color);
    border-radius: 15px;
    box-shadow: 0 0 5px gold;
    margin-Top: 20px;
    justify-content: center;
    border-color: transparent;

}

.CategoryDivLeft {
  flex: 4;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  margin-inline: 1rem;
  font-weight: 500;
}

.CategoryDivRight {
  flex: 4;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  margin-inline: 1rem;
  font-weight: 400;


}

.resetButton{
background-color: var(--secondary-background-color);
font-family: var(--main-font-family);
text-transform: lowercase;
font-size: 1rem;
border: var(--main-no-border);
color: var(--main-font-color);
border-radius: 16px;
padding: 6px;
}


.MuiButtonBase-root.resetButton:hover { 
    background-color: var(--main-background-color2);
    box-shadow: 0 0 10px #000;
}

/* <-------------------  CSS for the drop down menu -------------------> */

  
  .MuiMenuItem-root.menuItem {   
    font-size: 1em;
    color: var(--main-font-color);
    font-family: var(--main-font-family);
    text-transform: lowercase;
justify-content: center;
  }

  .MuiMenuItem-root.menuItem.hebMenuItem {
    font-weight: 600;

  }
  
  .MuiButtonBase-root.Mui-selected.menuItem {
    z-index: 1;    
    font-size: 1.2em;
    background-color: var(--main-selected-color);
  }
  
  .MuiList-root.MuiMenu-list {
    background-color: var(--secondary-background-color);
    border-radius: 16px;
  }

  .MuiSvgIcon-root.MuiSelect-icon{
    color: var(--main-font-color);
  }
  
  #search-bar-label {
    color: #ffffff;
   
  }

  .selectDropDown{
    background-color: var(--secondary-background-color);
    border-radius: 16px;
    font-family: var(--main-font-family);
    border:var(--main-no-border);
    color: var(--main-label-color);
    color:#ffffff;
    border-radius:16px!important; 
    text-transform:lowercase;
    text-align: center;
    min-height: 2.5rem;
    
    font-family:'anisette-std','Rubik';  
  }

    .collapsible-container {
      overflow: hidden;
      height: auto; /* Set to 'auto' to allow dynamic content height */
      max-height: 10000px; /* Set a maximum height that is taller than your content */
      transition: max-height 2s ease; /* Adjust the duration and easing function as needed */
    }
    
    .collapsible-container.collapsed {
      max-height: 0;
      transition: max-height 0.5s ease;
      overflow: hidden; /* Ensure overflow is set to 'hidden' when collapsed */
    }
    
    .collapsible-content {
      padding: 2px;
    }

  
  #demo-simple-select-label {
    color: var(--main-label-color);
    font-family: var(--main-font-family);
    background-color: var(--secondary-background-color);
    text-transform: lowercase;
  }
  
  .MuiPaper-root.MuiPopover-paper {
    background-color: transparent;
    border-radius: 16px;
    margin-top: 1px;
  }
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 0px!important;
    /* border-color: #fff !important; */
  }

  .MuiGrid-root.MuiGrid-item.hebDropDown {
    direction: rtl;
  }

  .MuiFormControl-root.MuiFormControl-fullWidth.hebDropDown {
    direction: rtl;
  }



/* <-------------------  CSS for the reset button -------------------> */



.resetButtonDiv {
    display: flex;
    margin-top: 10px;
    justify-content: flex-start;
    font-family: var(--main-font-family);
  }

.resetButtonDiv.hebReset{
  direction: rtl;
}
  
  .css-1e6y48t-MuiButtonBase-root-MuiButton-root.resetButton {
    color: white;
    font-size: 1.5em;
    border-radius: 16px;
    margin: 1rem;
    background-color: '#3c27c5';
    border-radius: '16px!important';
  }
  
  .css-1e6y48t-MuiButtonBase-root-MuiButton-root.resetButton:hover {
    background-color: #a52b2b;
  }
  

  
    